import Api, { useFetchApi } from './index';

export interface IKiosk {
  id?: number;
  name: string;
  address: string;
  askGlonassId: string;
  description?: string;
  controllerIp: string;
  boards?: IBoard[];
  active?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface IBoard {
  id: any;
  name: string;
  title: string;
  kiosk: IKiosk;
  groups?: any[];
  active: boolean;
  createdAt: string;
  updatedAt: string;
  version: number;
}

export enum AdDuration {
  DURATION_5S = '5S',
  DURATION_10S = '10S',
  DURATION_15S = '15S',
  DURATION_30S = '30S',
  DURATION_45S = '45S',
  DURATION_60S = '60S',
}

export enum AdFormat {
  FULLSCREEN = 'FULLSCREEN',
  HALFSCREEN = 'HALFSCREEN',
  FEED_1X1 = 'FEED_1X1',
  FEED_1X2 = 'FEED_1X2',
  FEED_2X2 = 'FEED_2X2',
  FEED_3X2 = 'FEED_3X2',
}

export const FeedFormat = {
  [AdFormat.FULLSCREEN]: [1, 1],
  [AdFormat.FEED_1X1]: [1, 1],
  [AdFormat.FEED_1X2]: [1, 2],
  [AdFormat.FEED_2X2]: [2, 2],
  [AdFormat.FEED_3X2]: [3, 2]
}

export interface IMainTile {
  id: number;
  adId: number;
  title: string;
  shortText: string;
  mainText: string;
  video: string;
  source: string;
  image: string;
  showDuration: AdDuration;
  format: AdFormat;
}

export interface IFeedTile {
  adId: number;
  image: string;
  title: string;
  format: AdFormat;
}

export interface ILayout {
  main: IMainTile[];
  feed: IFeedTile[][];
}

export function useBoardsList() {
  return useFetchApi(
    async () => Api('/board/public/list'),
    { data: [] },
    true,
    'useBoardsList'
  )
}

export function useBoardInfo() {
  return useFetchApi(
    async (id: number) => Api(`/board/public/board/${id}`),
    { data: {} },
    true,
    'useBoardInfo'
  )
}

export function useBoard() {
  return useFetchApi(
    async (q: any) => Api.post('/board/public/layout', q),
    { data: null },
    true,
    'useBoard'
  )
}

export function useNewLayout() {
  return useFetchApi(
    async (id: number) => Api.get(`/board/public/layout_v2/${id}`),
    { data: null },
    true,
    'useNewLayout'
  )
}
