import { IPaletteTheme } from '../type';
import { commonPalette } from '../common';

const palette: IPaletteTheme = {
  ...commonPalette,
  text: {
    default: 'rgba(255, 255, 255, 0.85)',
    subtitle: 'rgba(256,256,256,0.38)',
    content: 'rgba(256,256,256,0.6)',
    blue: '#007AFF'
  },
  background: {
    default: '#000',
    alternative: '#1F1F1F',
    semiTransparent: 'rgba(0,0,0,0.7)',
  },
  arriveTimeBg: {
    red: '#AB210A',
    orange: '#E2930E',
    green: '#448302'
  },
  arriveTimeText: {
    red: 'rgba(255, 255, 255, 0.85)',
    orange: 'rgba(255, 255, 255, 0.85)',
    green: 'rgba(255, 255, 255, 0.85)',
  }
}

export default palette;
