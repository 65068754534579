import React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { BrowserRouter } from 'react-router-dom';

import Routes from './Routes';
import LightTheme from './theme/light';
import DarkTheme from './theme/dark';
import './App.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export const themeIsDark = window.location.search.includes('theme=dark');

function App() {
  const theme = window.location.search.includes('theme=dark') ? DarkTheme : LightTheme;

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
