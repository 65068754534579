import { IPaletteTheme } from '../type';
import { commonPalette } from '../common';

const palette: IPaletteTheme = {
  ...commonPalette,
  text: {
    default: '#000',
    subtitle: 'rgba(0,0,0,0.38)',
    content: 'rgba(0,0,0,0.6)',
    blue: '#007AFF'
  },
  background: {
    default: '#fff',
    alternative: '#EFEFF4',
    semiTransparent: 'rgba(255,255,255,0.85)',
  },
  arriveTimeBg: {
    red: 'rgba(171, 33, 10, 0.15)',
    orange: 'rgba(226,147,14, 0.15)',
    green: 'rgba(68,131,2,0.15)'
  },
  arriveTimeText: {
    red: '#AB210A',
    orange: '#E2930E',
    green: '#448302',
  }
}

export default palette;
