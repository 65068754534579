import React, { FC, useEffect, useState } from 'react';
import { IMainTile } from '../../../api/boards';
import { getAdDurationInSeconds } from '../../../constants';
import MainView from './components/MainViewItem';
import { useStyles, getTileStyle } from './MainNews';
import { adShown } from '../../../api/news';

export interface LayoutProps {
  getLayout: () => void;
  layout: IMainTile[];
  boardId: any;
  boardVersion: number;
}

const logView = (nextIndex: number, boardId: string, boardVersion: number, layout: IMainTile[]) => {
  const isTracking = window.location.search.includes('track=1');

  console.info('Layout logView called')
  if (isTracking && !!layout[nextIndex].adId) {
    const adId = layout[nextIndex].adId;

    // В зависимости от версии киоска отправляем аналитику
    // @ts-ignore
    if (window.adShownInElectron && boardVersion > 1) {
      // @ts-ignore
      window.adShownInElectron({
        adId,
        boardId: Number(boardId),
        utcTimestamp: new Date(new Date().toUTCString()).getTime()
      });
    } else {
      adShown({ adId, boardId: Number(boardId) });
    }
  }

  // Track all media (use for views counter):
  if (isTracking) {
    const media = layout[nextIndex];
    // В зависимости от версии киоска отправляем аналитику
    // @ts-ignore
    if (window.mediaShownInElectron && boardVersion > 1) {

      const mediaDuration = parseInt(media.showDuration.replace('S', '') || "0");

      // @ts-ignore
      window.mediaShownInElectron({
        mediaId: media.id,
        mediaType: media.hasOwnProperty('adId') ? 'AD' : 'NEWS',
        adId: media.adId,
        boardId: Number(boardId),
        utcTimestamp: new Date(new Date().toUTCString()).getTime(),
        duration: mediaDuration
      });
    }
  }
}

const noop = () => {}

export const Layout: FC<LayoutProps> = ({ getLayout, layout, boardId, boardVersion }) => {
  const classes = useStyles();

  const [currentLayout, setCurrentLayout] = useState<IMainTile[]>();
  const [activeItemIdx, setActiveItemIdx] = useState<number>(0);
  const [shouldWaitForNewLayout, setShouldWaitForNewLayout] = useState(false);

  const activeItem = currentLayout?.[activeItemIdx];
  const isAd = !!activeItem?.adId

  useEffect(() => {
    if (!shouldWaitForNewLayout) {
      console.info('Layout set new layout and reset index')
      setCurrentLayout(layout);
      setActiveItemIdx(0);
    }
  }, [layout, shouldWaitForNewLayout]);

  // Effect to handle displaying items and requesting new ones
  useEffect(() => {
    let getLayoutTimeoutId: NodeJS.Timeout
    let displayDurationTimeoutId: NodeJS.Timeout;

    console.info('Layout effect current index = ', activeItemIdx)

    if (activeItemIdx === layout.length - 1) {
      setShouldWaitForNewLayout(true)
      console.info('Layout effect call getLayout and wait for ad to finish')

      getLayout()

      getLayoutTimeoutId = setTimeout(() => {
        console.info('Layout timeout end and allow for new layout to be shown')
        setShouldWaitForNewLayout(false)
      }, getAdDurationInSeconds(layout[activeItemIdx].showDuration) * 1000)
    }

    // Check if we are on the last item
    if (activeItemIdx < layout.length - 1) {
      // Set timeout for the duration of the current item
      displayDurationTimeoutId = setTimeout(() => {
        setActiveItemIdx((prevIndex) => {
          let newIdx = prevIndex + 1

          logView(newIdx, boardId, boardVersion, layout)

          return newIdx
        });

        console.info('Layout effect set new index')
      }, getAdDurationInSeconds(layout[activeItemIdx].showDuration) * 1000);
    }

    // Cleanup timeouts on component unmount or state change
    return () => {
      console.info('Layout effect cleanup call')
      clearTimeout(getLayoutTimeoutId);
      clearTimeout(displayDurationTimeoutId);
    };
  // getLayout changed on every render, so we remove it from the deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemIdx]);

  console.info('Layout render with active idx = ', activeItemIdx, ` and ${isAd ? 'adId' : 'newsId'} = `, isAd ? activeItem?.adId : activeItem?.id)

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {activeItem
          ? layout.map((item, i) => (
            <MainView
              item={item}
              boardVersion={boardVersion}
              style={getTileStyle(i, activeItemIdx, !!item.adId, false, false)}
              active={i === activeItemIdx}
              shouldBeRendered={[activeItemIdx, activeItemIdx + 1].includes(i) || (activeItemIdx === layout.length - 1 && i === 0)}
              key={`${item.id}_${i}`}
              setFullViewing={noop}
              full={false}
              pip={false}
            />
          )) : <>Нет данных</>}
      </div>
    </div>
  );
}