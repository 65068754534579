import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import isElectron from 'is-electron';
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

import { ITheme } from "../../../../theme/type";

const LOCAL_VIDEO_TRIES_COUNT = 3
const TOTAL_VIDEO_TRIES_COUNT = 6

const HEAD_TIMEOUT = 5 * 1000; // 5sec
const BLOB_TIMEOUT = HEAD_TIMEOUT * 6; // 30sec
const MAX_BLOB_CHUNK = 512 * 1024; // 512kb

const urlVideoToLocal = (url: string, boardVersion?: number) => {
  if (!url || !isElectron() || boardVersion === 1) {
    return url;
  }

  const relative = url?.split('/citytv-media/')?.[1] ||
      url?.split('citytv-media.storage.yandexcloud.net/')?.[1] ||
      false

  return relative
      ? 'http://localhost:3000/storage/' + decodeURI(relative)
      : url
}

const useStyles = makeStyles((theme: ITheme) => ({
  background: {
    width: '100%',
    height: ({ isPip, isFullScreen, mode }: any) => {
      const fullHeight = mode === 'old' ? 3050 : 3840;
      return isPip ? (3050 / 2 * 0.9) : isFullScreen ? fullHeight : 1680;
    },
    'background-color': theme.palette.background.default,
  },
  video: {
    width: '100%',
    height: ({ isPip, isFullScreen, mode }: any) => {
      const fullHeight = mode === 'old' ? 3050 : 3840;
      return isPip ? (3050 / 2 * 0.9) : isFullScreen ? fullHeight : 1680
    },
  },
}))

interface IProps {
  url: string;
  isFullScreen: boolean;
  isPip: boolean;
  shouldBeRendered?: boolean;
  isActive: boolean;
  boardVersion: number;
  setLoaded(arg0: boolean): void;
}

type VideoMode = 'fullscreen' | 'old';

export default ({
  url,
  setLoaded,
  shouldBeRendered,
  isActive,
  isFullScreen,
  boardVersion,
  isPip,
}: IProps) => {
  const mode: VideoMode = boardVersion === 1 ? 'old' : 'fullscreen'
  const classes = useStyles({ isFullScreen, isPip, mode });

  const [videoSrc, setVideoSrc] = useState<any>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (shouldBeRendered && videoRef.current) {
      if (isActive) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      } else {
        videoRef.current.currentTime = 0;
        videoRef.current.pause();
      }
    }
  }, [isActive, shouldBeRendered]);

  useEffect(() => {
    let videoUrl = '';
    let retryCount = 0

    const onVideoLoaded = (videoUrl: string) => {
      console.log('DEBUG set video src and call setLoaded callback for video with url', url)

      setVideoSrc(videoUrl)
      setLoaded(true)
    };

    const load = async (attemptLocal: boolean) => {
      console.log('DEBUG try to load video ', url, 'attemptLocal is ', attemptLocal)

      try {
        const videoSrc = attemptLocal ? urlVideoToLocal(url, boardVersion) : url;
        const head = await fetch(videoSrc, { method: 'HEAD', cache: 'no-store' });

        if (!head.ok) {
          throw new Error('Failed to fetch video HEAD');
        }

        const acceptRanges = head.headers.get('accept-ranges');
        const contentLength = head.headers.get('content-length');
        const size = contentLength ? Number(contentLength) : 0;

        if (acceptRanges === 'bytes') {
          let chunks = Math.abs(size / MAX_BLOB_CHUNK);
          if (size > chunks * MAX_BLOB_CHUNK) chunks += 1;
          const blobs = [];
          for (let i = 0; i < chunks; i++) {
            let fetchTo = (i + 1) * MAX_BLOB_CHUNK;

            if (fetchTo > size) {
              fetchTo = size;
            }

            const resp = await axios.get(
              videoSrc,
              {
                headers: {
                  'Range': `bytes=${i * MAX_BLOB_CHUNK}-${fetchTo-1}`,
                  'Accept': '*/*'
                },
                responseType: 'blob', timeout: BLOB_TIMEOUT
              })

            blobs.push(resp.data);
          }

          videoUrl = URL.createObjectURL(new Blob(blobs));
          console.log('created blob for ', videoSrc)
          onVideoLoaded(videoUrl);
        } else {
          const resp = await axios.head(url, { responseType: 'blob', timeout: BLOB_TIMEOUT })

          videoUrl = URL.createObjectURL(resp.data);
          onVideoLoaded(videoUrl);
        }
      } catch (err) {
        retryCount += 1
        if (attemptLocal && retryCount < LOCAL_VIDEO_TRIES_COUNT) {
          load(true); // Retry local video
        } else if (retryCount < TOTAL_VIDEO_TRIES_COUNT) {
          load(false); // Fallback to remote URL after retries
        }
      }
    }

    load(true);

    return function cleanup() {
      URL.revokeObjectURL(videoUrl);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return shouldBeRendered ? (
    <video
      ref={videoRef}
      src={videoSrc}
      className={classes.video}
      autoPlay
      muted={!isElectron()}
      playsInline
      disablePictureInPicture
    />
  ) : null
}
