import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import clsx from 'clsx';
import diffMin from 'date-fns/differenceInMinutes';

import { ITheme } from '../../../../theme/type';
import { IRoute } from '../../../../api/transport';
import BusIcon from './icons/bus.svg';
import TramIcon from './icons/tram.svg';
import TrolleybusIcon from './icons/trolleybus.svg';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    flex: '0 0 760px',
    width: 760,
    height: 260,
    borderRadius: 21,
    '&.old-version': {
      height: 396,
      borderRadius: 20,
    },
    background: 'no-repeat top 40px left 30px',
    marginRight: 40,
    '&.bus, &.routetaxi': {
      backgroundImage: `url("${BusIcon}")`,
      backgroundColor: theme.palette.mark.red
    },
    '&.tram': {
      backgroundImage: `url("${TramIcon}")`,
      backgroundColor: theme.palette.mark.green,
      backgroundPosition: 'top 40px left 35px'
    },
    '&.trolleybus': {
      backgroundImage: `url("${TrolleybusIcon}")`,
      backgroundColor: theme.palette.mark.orange,
      backgroundPosition: 'top 40px left 34px'
    }
  },
  content: {
    marginLeft: 110,
    background: theme.palette.background.default,
    height: 260,
    borderRadius: '0 20px 20px 0',
    padding: '50px 45px 50px',
    '&.old-version': {
      height: 396,
      padding: '70px 45px 50px',
    },
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.default,
    height: 110
  },
  number: {
    fontFamily: 'SF Pro Display',
    fontWeight: 700,
    fontSize: 138,
    lineHeight: '110px',
  },
  arrive: {
    width: 220,
    height: 90,
    borderRadius: 45,
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 53,
    lineHeight: '53px',
    paddingTop: 18,
    paddingBottom: 18,
    letterSpacing: '-2.34px',
    '&.bus, &.routetaxi': {
      background: theme.palette.arriveTimeBg.red,
      color: theme.palette.arriveTimeText.red
    },
    '&.tram': {
      background: theme.palette.arriveTimeBg.green,
      color: theme.palette.arriveTimeText.green
    },
    '&.trolleybus': {
      background: theme.palette.arriveTimeBg.orange,
      color: theme.palette.arriveTimeText.orange
    }
  },
  destination: {
    color: theme.palette.text.default,
    '& .ru': {
      fontWeight: 600,
      letterSpacing: '-1.92px',
      fontSize: 36,
      lineHeight: '43px',
      ':not(.old-version) &': {
        marginTop: 25,
      },
    },
    '& .en': {
      ':not(.old-version) &': {
        marginTop: 25,
      },
      fontWeight: 500,
      letterSpacing: '-1.6px',
      fontSize: 30,
      lineHeight: '36px',
      color: theme.palette.text.subtitle
    }
  }
}));

interface IProps {
  item: IRoute;
  mode: 'fit' | 'old';
}

export default function TransportItem({ item, mode }: IProps) {
  const classes = useStyles();
  const wait = diffMin(item.arriveAt, Date.now());

  return (
    <div className={clsx(classes.root, item.type.toLowerCase(), mode !== 'fit' ? 'old-version' : '')}>
      <div className={clsx(classes.content, mode !== 'fit' ? 'old-version' : '')}>
        <div className={classes.top}>
          <div className={classes.number}>{item.number}</div>
          <div className={clsx(classes.arrive, item.type.toLowerCase())}>{wait > 0 ? `${wait} мин` : 'Рядом'}</div>
        </div>
        <div className={clsx(classes.destination, mode !== 'fit' ? 'old-version' : '')}>
          <div className="ru">{item.destinationRu}</div>
          <div className="en">{item.destinationEn}</div>
        </div>
      </div>
    </div>
  );
}
