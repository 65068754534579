import { AdDuration } from './api/boards';

const adDurations = {
  [AdDuration.DURATION_5S]: 5,
  [AdDuration.DURATION_10S]: 10,
  [AdDuration.DURATION_15S]: 15,
  [AdDuration.DURATION_30S]: 30,
  [AdDuration.DURATION_45S]: 45,
  [AdDuration.DURATION_60S]: 60,
};

export const getAdDurationInSeconds = (duration: AdDuration): number => adDurations[duration];