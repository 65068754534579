import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import clsx from 'clsx';
import React, { useState } from 'react';

import { AdFormat, IMainTile } from '../../../../api/boards';
import { ITheme } from '../../../../theme/type';
import Video from './Video';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    width: 2160,
    height: ({ isFullScreen }: any) => isFullScreen ? 3840 : 1680,
    position: 'absolute',
    left: 0,
    top: 0,
    background: ({ image, isVideoLoaded }: any) => isVideoLoaded ? 'none' : `url("${image}") no-repeat center / cover ${theme.palette.background.default}`,
    opacity: 0
  },
  content: {
    width: '100%',
    height: ({ pip }: any) => pip ? (3050 / 4 * 0.9) : 1680,
    maxHeight: ({ pip }: any) => pip ? (3050 / 4 * 0.9) : 1680,
    minHeight: ({ pip }: any) => pip ? (3050 / 4 * 0.9) : 1680,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0.9) 100%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: ({ pip }: any) => pip ? '78px 90px' : '156px 90px',
    color: '#fff',
    overflow: 'hidden',
    '&.full': {
      backdropFilter: 'blur(20px)'
    }
  },
  title: {
    fontSize: 72,
    fontWeight: 700,
    lineHeight: '90px',
    letterSpacing: '-2.48px',
    marginBottom: ({ pip }: any) => pip ? 10 : 40,
    width: '49%'
  },
  text: {
    fontSize: 28,
    lineHeight: '44px',
    letterSpacing: '-0.97px',
    width: '49%'
  }
}))

export interface IMainTileStyle {
  opacity: number;
  zIndex: number;
  left?: number;
  width?: number;
  height?: number;
  borderLeft?: string;
  borderBottom?: string;
  transition: string;
}

interface IProps {
  item: IMainTile;
  setFullViewing: any;
  full: boolean;
  style: IMainTileStyle;
  pip: boolean;
  active: boolean;
  boardVersion: number;
  shouldBeRendered?: boolean;
}

export default function MainView({ item, shouldBeRendered, setFullViewing, style, full, pip, active, boardVersion }: IProps) {
  const [isVideoLoaded, setVideoLoaded] = useState(false);
  const classes = useStyles({ image: item.image, isVideoLoaded, isFullScreen: item.format === AdFormat.FULLSCREEN, pip });

  return (
    <div
      className={classes.root}
      onClick={() => {
        if (!pip && item.format !== AdFormat.FULLSCREEN && !isVideoLoaded) {
          setFullViewing(!full);
        }
      }}
      style={style}
    >
      {item.video && (
        <Video
          url={item.video}
          setLoaded={setVideoLoaded}
          shouldBeRendered={shouldBeRendered && isVideoLoaded}
          isActive={active || pip}
          isFullScreen={item.format === AdFormat.FULLSCREEN}
          boardVersion={boardVersion}
          isPip={pip}
        />)}
      {item.format !== AdFormat.FULLSCREEN && !isVideoLoaded && item.title && (
        <div className={clsx(classes.content, { full: full && !pip })}>
          <div className={classes.title}>{item.title}</div>
          <div className={classes.text} dangerouslySetInnerHTML={{ __html: full && !pip ? item.mainText : item.shortText }} />
        </div>
      )}
    </div>
  )
}
